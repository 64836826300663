import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TylerNavbar } from 'src/app/navbar/tylernavbar';
import { GlobalVars } from 'src/app/global-vars';
import { ParticlesJS } from 'src/app/particlesjs';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  navbar: TylerNavbar;
  globals: GlobalVars;
  particles: ParticlesJS;
  baseURL!: string;

  constructor(private titleService: Title, private router: Router) {
    this.navbar = new TylerNavbar('Home');
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    this.baseURL = this.router.url;
    setTimeout(() => {
      this.particles.loadTSParticles();
      this.particles.createParticlesButtons();
    }, 1);
    this.titleService.setTitle('Home | Tyler Wrenn');
  }
}
