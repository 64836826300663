import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TylerNavbar} from 'src/app/navbar/tylernavbar';
import {ParticlesJS} from 'src/app/particlesjs';
import {GlobalVars} from 'src/app/global-vars';
import {Router} from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  navbar: TylerNavbar;
  particles: ParticlesJS;
  globals: GlobalVars;
  baseURL!: string;

  constructor(private titleService: Title, private router: Router) {
    this.navbar = new TylerNavbar('My Portfolio');
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    this.baseURL = this.router.url;
    setTimeout(() => {
      this.particles.loadTSParticles();
      this.particles.createParticlesButtons();
    }, 1);
    this.titleService.setTitle('Portfolio | Tyler Wrenn');
  }

}
