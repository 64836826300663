/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { GlobalVars } from 'src/app/global-vars';
import { TylerNavbar } from 'src/app/navbar/tylernavbar';
import { ParticlesJS } from 'src/app/particlesjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-inquiries',
  templateUrl: './inquiries.component.html',
  styleUrls: ['./inquiries.component.scss']
})
export class InquiriesComponent implements OnInit {
  FormData!: UntypedFormGroup;
  captchaResponse: string;
  globals: GlobalVars;
  navbar: TylerNavbar;
  particles: ParticlesJS;
  baseURL!: string;
  firstname = new UntypedFormControl('', Validators.required);
  lastname = new UntypedFormControl('', Validators.required);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  formSubject = new UntypedFormControl('', Validators.required);
  formMessage = new UntypedFormControl('', Validators.required);

  constructor(private titleService: Title,
      private builder: UntypedFormBuilder,
      private http: HttpClient,
      private router: Router,
      private $gaService: GoogleAnalyticsService) {
    this.navbar = new TylerNavbar('Inquiries');
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
    this.captchaResponse = '';
  }

  resolved(event: string | null): void {
    console.log(`Captcha was validated`);
    this.captchaResponse = event !== null ? JSON.parse(event) : [];
  }

  ngOnInit(): void {
    this.baseURL = this.router.url;
    setTimeout(() => {
      this.particles.loadTSParticles();
      this.particles.createParticlesButtons();
    }, 1);
    this.titleService.setTitle('Inquiries | Tyler Wrenn');
  }

  onSubmit(form: NgForm): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const statusOnDoc = document.getElementById('status')!;
    if (statusOnDoc === null || statusOnDoc === undefined) {
      throw new Error('Status was not found!');
    }
    if (form.valid && statusOnDoc != null && statusOnDoc !== undefined) {
      const data = {
        firstname: this.firstname.value,
        lastname: this.lastname.value,
        email: this.email.value,
        subject: this.formSubject.value,
        message: this.formMessage.value,
        gRecaptchaResponse: this.captchaResponse
      };
      console.log(data);

      const observer = {
        next: (response: any) => {
          if (response.status === 'success') {
            this.$gaService.event('submit_success', 'user_inquiry_form', 'inquiries_page');
            statusOnDoc.innerHTML = 'Message was sent successfully!';
            statusOnDoc.classList.add('alert', 'alert-success');
          } else {
            this.$gaService.event('submit_failure', 'user_inquiry_form', 'inquiries_page');
            statusOnDoc.innerHTML = 'There was an error. Please try filling out the form again.';
            statusOnDoc.classList.add('alert', 'alert-danger');
          }
        },
        error: (error: any) => {
          this.$gaService.event('submit_failure', 'user_inquiry_form', 'inquiries_page');
          statusOnDoc.innerHTML = 'There was an error. Please try filling out the form again.';
          statusOnDoc.classList.add('alert', 'alert-danger');
          console.log(error);
        }
      };
      this.http.post('https://tylerwrenn.com/contactapi/index.php', JSON.stringify(data)).subscribe(observer);
    } else {
      if (this.firstname.invalid) {
        statusOnDoc.innerHTML = 'You must enter a first name.';
        statusOnDoc.classList.add('alert', 'alert-danger');
      }
    }
  }
}
