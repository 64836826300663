<main>
  <div class="jumbotron-constrain ds-u-display--block">
    <ng-particles id="tsparticles" [particlesInit]="particles.particlesInit"></ng-particles>
    <div class="container ds-u-text-align--center ds-u-padding-y--6">
      <h1 class="site-title">Tyler Wrenn</h1>
      <nav>
        <ul class="nav nav-pills ds-u-justify-content--center">
          <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
            <span *ngIf="navbar.isNormalNavLink(navLinks)">
              <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
                (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}" role="link">{{ navLinks.navLinkName }} <span
                  class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
            </span>
            <span *ngIf="navbar.isSocialNavLink(navLinks)">
              <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)" title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
                  class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span class="ds-u-visibility--screen-reader">{{ navLinks.socialLinkTitle }}</span></i></a>
            </span>
            <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
              <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <section class="bg-dark ds-u-padding-y--6">
    <div class="container">
      <h1 class="display-4">Testing Area</h1>
      <p class="lead">This is an area where I test features in Angular.</p>
    </div>
  </section>
  <section class="bg-dark ds-u-text-align--center ds-u-padding-y--6">
    <div class="container">
      <hr class="border-white" />
      <footer>
        <p class="ds-text-body--sm">Designed by Tyler Wrenn<br /><span id="pButtons"></span></p>
      </footer>
    </div>
  </section>
</main>
