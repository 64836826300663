import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tyler-wrenn-website';
  modesLoaded = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const themeMode = localStorage.getItem('mode-class');
    if (themeMode === null || themeMode === undefined) {
      if (!environment.production) {
        console.log('Set the mode-class from local storage');
      }

      if (window.matchMedia('(prefers-color-scheme: dark)').media === 'not all') {
        // Set to light mode if it isn't supported in-browser
        localStorage.setItem(
          'mode-class',
          JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base' })
        );
      } else {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        if (!mediaQuery || mediaQuery.matches) {
          localStorage.setItem(
            'mode-class',
            JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base--inverse' })
          );
        } else {
          localStorage.setItem(
            'mode-class',
            JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base' })
          );
        }
      }
    }
    this.modesLoaded = true;

    if (!environment.production) {
      console.log('Tyler Wrenn\'s website loaded.');
    }

    this.setThemeMode();
  }

  setThemeMode(): void {
    console.log('Replacement called');
    const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');
    if (themeParse.oldValue) {
      if (!environment.production) {
        console.log('Old Value: ' + themeParse.oldValue);
      }
      this.renderer.removeClass(document.body, themeParse.oldValue);
    }

    if (!environment.production) {
      console.log('New Value: ' + themeParse.newValue);
    }
    this.renderer.addClass(document.body, themeParse.newValue);
  }
}
