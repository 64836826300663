<main>
  <div class="container" style="width: 8.5in;">
    <ng-particles id="tsparticles" [particlesInit]="particles.particlesInit"></ng-particles>
    <h1 class="site-title pt-5">Tyler Wrenn</h1>
  </div>
  <section class="py-5">
    <div class="container">
    </div>
  </section>
</main>
