<main>
  <div class="jumbotron-constrain d-block">
    <ng-particles id="tsparticles" [particlesInit]="particles.particlesInit"></ng-particles>
    <div class="container text-center py-5">
      <h1 class="site-title">Tyler Wrenn</h1>
      <nav>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
            <span *ngIf="navbar.isNormalNavLink(navLinks)">
              <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
                (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}" role="link">{{ navLinks.navLinkName }} <span
                  class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
            </span>
            <span *ngIf="navbar.isSocialNavLink(navLinks)">
              <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)" title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
                  class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span class="sr-only">{{ navLinks.socialLinkTitle }}</span></i></a>
            </span>
            <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
              <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <section class="bg-gray-dark py-5">
    <div class="container">
      <h2 class="display-3 text-center">404 Not Found</h2>
      <p class="lead text-center">The requested URL {{ routeURL }} was not found.</p>
    </div>
  </section>
</main>
