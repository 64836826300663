/* eslint-disable id-blacklist */
import { Container, tsParticles, MoveDirection, ClickMode, HoverMode, OutMode, Engine } from 'tsparticles-engine';
import { loadFull } from 'tsparticles';
import { IParticlesParams } from 'ng-particles';
import { environment } from '../environments/environment';

export class ParticlesJS {
  private tscontainer!: Container;

  constructor() {
    if (!environment.production) {
      console.log('TS particles class loaded.');
    }
  }

  public id = 'tsparticles';

  public particlesWhiteOptions: IParticlesParams = {
    autoPlay: true,
    fpsLimit: 120,
    interactivity: {
      detectsOn: 'window',
      events: {
        onClick: {
          enable: true,
          mode: ClickMode.push
        },
        onHover: {
          enable: true,
          mode: HoverMode.repulse
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: '#495057',
      },
      links: {
        color: '#495057',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: MoveDirection.none,
        enable: true,
        outModes: {
          default: OutMode.bounce
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: 'circle',
      },
      size: {
        random: true,
        value: 5,
      },
    },
    detectRetina: true,
    pauseOnBlur: true,
    pauseOnOutsideViewport: true,
    zLayers: 100
  };

  public particlesOptions: IParticlesParams = {
    autoPlay: true,
    fpsLimit: 120,
    interactivity: {
      detectsOn: 'window',
      events: {
        onClick: {
          enable: true,
          mode: ClickMode.push
        },
        onHover: {
          enable: true,
          mode: HoverMode.repulse
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: '#ffffff',
      },
      links: {
        color: '#ffffff',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: MoveDirection.none,
        enable: true,
        outModes: {
          default: OutMode.bounce
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: 'circle',
      },
      size: {
        random: true,
        value: 5,
      },
    },
    detectRetina: true,
    pauseOnBlur: true,
    pauseOnOutsideViewport: true,
    zLayers: 100
  };

  private getTSParticlesContainer(): Container {
    return this.tscontainer;
  }

  private setTSParticlesContainer(cont: Container): void {
    this.tscontainer = cont;
  }

  loadTSParticles(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tToggler = document.getElementById('theme-toggler')!;
    tToggler.addEventListener('click', (e) => {
      window.location.reload();
    });

    const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (!mediaQuery || mediaQuery.matches) {
      // Do not load animation as reduced motion is detected
      console.log('Reduced motion detected. Turning off TS Particles.');
    } else {
      if (themeParse.newValue === 'ds-base') {
        this.loadWhiteTSParticles();
      } else {
        this.loadDarkTSParticles();
      }
    }
  }

  loadDarkTSParticles(): void {
    tsParticles.load(this.id, this.particlesOptions).then((container) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.setTSParticlesContainer(container!);
        if (!environment.production) {
          console.log(container);
          console.log('ParticleJS loaded. Used loadTSParticles() function.');
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  /**
   * Pauses particles JS and resumes it. Used as a toggle switch.
   */
  pauseTSParticles(): void {
    const particleButton = document.getElementById('pauseResumeParticles');
    if (particleButton != null) {
      if (this.tscontainer.getAnimationStatus()) {
        this.tscontainer.pause();
        particleButton.innerHTML = 'Click To Resume Particles | ';
        if (!environment.production) {
          console.log(
            'ParticleJS was told to pause using pauseTSParticles() function.'
          );
        }
      } else {
        this.tscontainer.play();
        particleButton.innerHTML = 'Click To Pause Particles | ';
        if (!environment.production) {
          console.log(
            'ParticleJS was told to resume using pauseTSParticles() function.'
          );
        }
      }
    } else {
      console.error('Could not find the pause/resume particles button!', 5);
    }
  }

  stopTSParticles(): void {
    const stopStartParticleButton = document.getElementById('stopStartParticles');
    if (stopStartParticleButton != null) {
      if (this.tscontainer.started) {
        this.tscontainer.stop();
        stopStartParticleButton.innerHTML = 'Click To Turn Particles Back On';
        if (!environment.production) {
          console.log(
            'ParticleJS was told to stop using stopTSParticles() function.'
          );
        }
      } else {
        this.tscontainer.start();
        stopStartParticleButton.innerHTML = 'Click To Stop Particles';
        if (!environment.production) {
          console.log(
            'ParticleJS was told to start using stopTSParticles() function.'
          );
        }
      }
    } else {
      console.error('Could not find the stop/start particles button!', 5);
    }
  }

  createParticlesButtons(): void {
    const particleButtons = document.getElementById('pButtons');
    const button1 = document.createElement('a');
    if (particleButtons != null) {
      const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
      if (!mediaQuery || mediaQuery.matches) {
        button1.innerHTML = 'Particles Turned Off Due to Reduced Motion Turned On';
        particleButtons.append(button1);
        if (!environment.production) {
          console.log('Disabled TS Particles create button for reduced motion.');
        }
      } else {
        if (!environment.production) {
          button1.addEventListener('click', (e: Event) =>
            this.pauseTSParticles()
          );
          button1.innerHTML = 'Click To Pause Particles | ';
          button1.id = 'pauseResumeParticles';
          particleButtons.append(button1);
        }
        const button2 = document.createElement('a');
        button2.addEventListener('click', (e: Event) => this.stopTSParticles());
        button2.innerHTML = 'Click to Stop Particles';
        button2.id = 'stopStartParticles';
        particleButtons.append(button2);
      }
    } else {
      console.error('Could not create the particles buttons!');
    }
  }

  loadWhiteTSParticles(): void {
    tsParticles.load(this.id, this.particlesWhiteOptions).then((container) => {
      console.log(container);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.setTSParticlesContainer(container!);
      if (!environment.production) {
        console.log(container);
        console.log('ParticleJS loaded. Used loadWhiteTSParticles() function.');
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  public async particlesInit(engine: Engine): Promise<void> {
    console.log(engine);

    // Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }
}
