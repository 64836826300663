<ng-particles id="tsparticles" [particlesInit]="particles.particlesInit"></ng-particles>
<main class="container">
  <h1 class="site-title">Tyler Wrenn</h1>
  <nav>
    <ul class="nav nav-pills justify-content-center">
      <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
        <span *ngIf="navbar.isNormalNavLink(navLinks)">
          <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
            (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}" role="link">{{ navLinks.navLinkName }} <span
              class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
        </span>
        <span *ngIf="navbar.isSocialNavLink(navLinks)">
          <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)" title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
              class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span class="sr-only">{{ navLinks.socialLinkTitle }}</span></i></a>
        </span>
        <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
          <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
        </span>
      </li>
    </ul>
  </nav>
  <p class="text-center">Welcome to my redesigned portfolio! Some features are still in beta and are a
    work in progress. In the meantime, click on a link provided to continue.</p>
</main>
<footer class="text-center">
  <p class="small">Designed by Tyler Wrenn<br /><span id="pButtons"></span></p>
</footer>
