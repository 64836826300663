import { Router } from '@angular/router';

export class GlobalVars {

  constructor(private router: Router) {
  }

  public navigate(baseURL: string, target: string): Promise<boolean> {
    return this.router.navigate([target]);
  }

  public goToSocialLink(target: string): void {
    if (target === 'facebook') {
      window.alert('You are about to open Facebook. Please note you must have a Facebook account to see my page.');
      window.open('https://facebook.com/tylerwrennftw', '_blank');
    } else if (target === 'twitter') {
      window.open('https://twitter.com/tweettylerwrenn', '_blank');
    } else if (target === 'linkedin') {
      window.open('https://www.linkedin.com/in/tylerwrenn/', '_blank');
    }
  }
}
