export class NavLink {
  public navLinkName!: string;
  public navLinkTarget!: string;
  public isNavLinkActive!: boolean;
  public socialLinkIcon!: string;
  public socialLinkTarget!: string;
  public socialLinkTitle!: string;
  public tabIndex!: number;

  constructor(public linkTabIndex: number) {
    this.tabIndex = linkTabIndex;
  }

  setNavLinkName(value: string) {
    this.navLinkName = value;
  }

  setNavLinkTarget(value: string) {
    this.navLinkTarget = value;
  }

  setNavLinkActive(value: boolean) {
    this.isNavLinkActive = value;
  }

  setTabIndex(value: number) {
    this.tabIndex = value;
  }

  setSocialLinkTitle(value: string) {
    this.socialLinkTitle = value;
  }

  setSocialLinkIcon(value: string) {
    this.socialLinkIcon = value;
  }

  setSocialLinkTarget(value: string) {
    this.socialLinkTarget = value;
  }
}

export class NormalNavLink extends NavLink {
  constructor(private linkName: string, private linkTarget: string, private isLinkActive: boolean, public linkTabIndex: number) {
    super(linkTabIndex);
    this.setNavLinkName(this.linkName);
    this.setNavLinkTarget(this.linkTarget);
    this.setNavLinkActive(this.isLinkActive);
  }
}

export class SocialLink extends NavLink {
  constructor(private linkTitle: string, private linkIcon: string, private linkTarget: string, public linkTabIndex: number) {
    super(linkTabIndex);
    this.setSocialLinkTitle(this.linkTitle);
    this.setSocialLinkIcon(this.linkIcon);
    this.setSocialLinkTarget(this.linkTarget);
 }
}

export class DarkModeToggleLink extends NavLink {
  constructor(public linkTabIndex: number) {
    super(linkTabIndex);
  }
}
