
export class PagePaths {

  constructor() {
  }

  public static paths = {
    home: '',
    resume: 'resume',
    portfolio: 'portfolio',
    inquiries: 'inquiries',
    linkDirector: 'link-director',
    logo: 'logo',
    testingEnvironment: 'testing'
  };
}
