<main>
  <div class="jumbotron-constrain d-block">
    <ng-particles id="tsparticles" [particlesInit]="particles.particlesInit"></ng-particles>
    <div class="container text-center py-5">
      <h1 class="site-title">Tyler Wrenn</h1>
      <nav>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
            <span *ngIf="navbar.isNormalNavLink(navLinks)">
              <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
                (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}" role="link">{{ navLinks.navLinkName }} <span
                  class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
            </span>
            <span *ngIf="navbar.isSocialNavLink(navLinks)">
              <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)" title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
                  class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span class="sr-only">{{ navLinks.socialLinkTitle }}</span></i></a>
            </span>
            <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
              <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <section class="ds-u-fill--gray-dark py-5">
    <div class="container">
      <h3 class="text-center py-4">My Portfolio</h3>
      <div class="row">
        <div class="col-md">
          <div class="portfolio-card">
            <div class="card-block">
              <img alt="PEERClinic for Back Pain and Spine Surgery" class="img-responsive w-80" src="assets/images/peer-logo.png" />
              <p class="lead">Orthopedic Surgeon in Northern Virginia</p>
              <p class="lead"><a class="btn btn-primary text-white" href="https://peerclinic.com/" rel="noopener noreferrer" role="button" target="_blank">Visit
                PEERClinic</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="portfolio-card">
            <div class="card-block">
              <img alt="Back Pain Tracker Logo" class="img-fluid w-50" src="assets/images/backpainlogo-withtext.svg" />
              <h3 class="display-6">Back Pain Tracker</h3>
              <p class="lead">An app for back pain focused on Patient Reported Outcomes.</p>
              <p class="lead"><a class="btn btn-primary text-white" href="https://app.peerclinic.com/" rel="noopener noreferrer" role="button"
                                 target="_blank">Visit
                Back Pain Tracker</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <div class="portfolio-card">
            <div class="card-block">
              <img alt="Groovin Gourmets" class="img-fluid w-30" src="assets/images/GroovinGourmets.png" />
              <h3 class="display-6">Groovin' Gourmets &amp; Trolley House Refreshments</h3>
              <p class="lead">Richmond, VA Catering Company: Weddings, Box Lunches, Corporate Catering, Movie Sets, and
                Special Events Catering in Virginia</p>
              <p class="lead"><a class="btn btn-primary text-white" href="https://groovingourmets.com/" rel="noopener noreferrer" role="button"
                                 target="_blank">Visit</a></p>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="portfolio-card">
            <div class="card-block">
              <img alt="PEERS RVA" class="img-fluid w-30" src="assets/images/peersrva-logo.png" />
              <h3 class="display-6">PEERS RVA</h3>
              <p class="lead">Evidence Based Social Skills Classes for Neurodiverse and Neurotypical Teens and Young
                Adults.</p>
              <p class="lead"><a class="btn btn-primary text-white" href="https://peersrva.com/" rel="noopener noreferrer" role="button" target="_blank">Visit</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ds-u-fill--gray-dark py-5">
    <div class="container">
      <p class="text-center">- More projects in the works -</p>
      <hr class="border-footer" />
      <footer class="text-center">
        <p class="small">Designed by Tyler Wrenn<br/>All copyrights are for their respected owners.</p>
      </footer>
    </div>
  </section>
</main>
