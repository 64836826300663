/* eslint-disable no-underscore-dangle */
import {DarkModeToggleLink, NavLink, NormalNavLink, SocialLink} from './navlink';
import {PagePaths} from '../pagepaths';

export class TylerNavbar {
  public navButtonClasses: string;
  private _navbarLinks: Array<NavLink>;

  getNavButtonClasses(navLink: NavLink): string {
    if (navLink.isNavLinkActive) {
      return 'nav-buttons active';
    } else {
      return this.navButtonClasses;
    }
  }

  getAriaAttribute(navLink: NavLink): string {
    if (navLink.isNavLinkActive) {
      return '(current)';
    } else {
      return '';
    }
  }

  constructor(activeLinkName?: string) {
    this.navButtonClasses = 'nav-buttons';
    this._navbarLinks = new Array<NavLink>();
    let homeLinkActive = false;
    let portfolioLinkActive = false;
    let resumeLinkActive = false;
    let inquiriesLinkActive = false;
    switch (activeLinkName) {
      case null:
        // Do nothing if it isn't specified
        break;
      case 'Home':
        homeLinkActive = true;
        break;
      case 'My Portfolio':
        portfolioLinkActive = true;
        break;
      case 'My Resume':
        resumeLinkActive = true;
        break;
      case 'Inquiries':
        inquiriesLinkActive = true;
        break;
    }
    this._navbarLinks.push(new NormalNavLink('Home', PagePaths.paths.home, homeLinkActive, 1));
    this._navbarLinks.push(new NormalNavLink('My Portfolio', PagePaths.paths.portfolio, portfolioLinkActive, 2));
    this._navbarLinks.push(new NormalNavLink('My Resume', PagePaths.paths.resume, resumeLinkActive, 3));
    this._navbarLinks.push(new NormalNavLink('Inquiries', PagePaths.paths.inquiries, inquiriesLinkActive, 4));
    this._navbarLinks.push(new SocialLink('Facebook', 'fab fa-lg fa-facebook-f', 'facebook', 5));
    this._navbarLinks.push(new SocialLink('Twitter', 'fab fa-lg fa-twitter', 'twitter', 6));
    this._navbarLinks.push(new SocialLink('LinkedIn', 'fab fa-lg fa-linkedin', 'linkedin', 7));
    this._navbarLinks.push(new DarkModeToggleLink(8));
  }

  public get getNavbar(): Array<NavLink> {
    return this._navbarLinks;
  }

  // noinspection JSUnusedLocalSymbols
  private set setNavbar(value: Array<NavLink>) {
    this._navbarLinks = value;
  }

  public isNormalNavLink(obj: NavLink): boolean {
    return obj instanceof NormalNavLink;
  }

  public isSocialNavLink(obj: NavLink): boolean {
    return obj instanceof SocialLink;
  }

  public isDarkModeToggleLink(obj: NavLink): boolean {
    return obj instanceof DarkModeToggleLink;
  }
}
